import buildQueryString from './../utils/helpers';
import {
  CONTENT_TYPE_JSON,
  DEBUG,
  HTTP_METHOD_GET,
  HTTP_METHOD_POST,
  OUT_FORMAT_JSON,
  OUT_FORMAT_BLOB,
  OUT_FORMAT_TEXT,
  GET_REQUEST_TIMEOUT,
} from './../utils/constants';

const CREDENTIALS = 'include'; // include
const MODE = 'cors';
const ACCESS_CONTROL_ALLOW_ORIGIN = '*';
const ACCESS_CONTROL_ALLOW_CREDENTIALS = true;

const snakeCaseKeys = require('snakecase-keys');

class ApiRequestError extends Error {
  constructor(message, status, body) {
    super();

    this.name = `ApiRequestError[${status}]`;
    this.message = message;
    this.status = status;
    this.body = body;

    try {
      const json = JSON.parse(body);
      this.body = json;
      if (json?.error) {
        this.message = json?.error;
      }
    } catch (e) {}

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

async function get(url, params = {}, outFormat = OUT_FORMAT_JSON) {
  let signal = null;
  let request_timeout_handler = null;

  if (window.hasOwnProperty('AbortController')) {
    // setup AbortController
    const controller = new AbortController();
    // signal to pass to fetch
    signal = controller.signal;

    request_timeout_handler = setTimeout(() => {
      controller.abort();
    }, GET_REQUEST_TIMEOUT);
  }

  let headers = {};
  let debug = params['debug'] ?? 0;
  if (parseInt(debug) === 1) {
    debug = true;
  } else {
    debug = DEBUG;
  }
  if (debug) {
    params = { ...params, ...(debug && { debug }) };
    headers = {
      ...headers,
      ...{
        debug: debug,
        'Access-Control-Allow-Origin': ACCESS_CONTROL_ALLOW_ORIGIN,
        'Access-Control-Allow-Credentials': ACCESS_CONTROL_ALLOW_CREDENTIALS,
      },
    };
  }
  const queryParams = buildQueryString(params); // JSON.stringify(params) === JSON.stringify({}) ? '' : `?${queryString.stringify(params)}`;

  let response = await fetch(`/web${url}` + queryParams, {
    method: HTTP_METHOD_GET,
    credentials: CREDENTIALS,
    mode: MODE,
    headers,
    signal: signal,
  }).catch((e) => {
    if (request_timeout_handler !== null) clearTimeout(request_timeout_handler);

    return {
      response: 'error',
      status: 404,
      statusText:
        'Request time to long. Connection to server slowly. Try again.',
      text: async () => 'Request was aborted',
    };
  });

  if (request_timeout_handler !== null) clearTimeout(request_timeout_handler);

  if (response.ok) {
    if (outFormat === OUT_FORMAT_BLOB) return await response.blob();

    if (outFormat === OUT_FORMAT_TEXT) return await response.text();

    return await response.json();
  } else {
    throw new ApiRequestError(
      response.statusText,
      response.status,
      await response.text()
    );
  }
}

async function post(url, data) {
  let headers = {
    'Content-Type': CONTENT_TYPE_JSON,
  };
  if (DEBUG) {
    headers = {
      ...headers,
      ...{
        debug: DEBUG,
        'Access-Control-Allow-Origin': ACCESS_CONTROL_ALLOW_ORIGIN,
        'Access-Control-Allow-Credentials': ACCESS_CONTROL_ALLOW_CREDENTIALS,
      },
    };
  }

  let response = await fetch(`/web${url}`, {
    method: HTTP_METHOD_POST,
    credentials: CREDENTIALS,
    mode: MODE,
    headers,
    body: JSON.stringify(snakeCaseKeys(data)),
  }).catch(async (e) => {
    console.log('Fetch catch error', e);
  });

  if (response?.ok) {
    return await response.json();
  } else {
    const status = response?.status || 'Unknown';
    const statusText = response?.statusText || 'Unknown error';
    const text = await response?.text();
    let jsonResponse = null;

    try {
      if ({}.constructor !== response?.constructor) {
        jsonResponse = JSON.parse(text);
      }
    } catch (e) {
      throw new ApiRequestError(statusText, status, text || 'Unknown');
    }

    if (jsonResponse?.hasOwnProperty('error')) {
      throw new ApiRequestError(jsonResponse?.error, status, statusText);
    }
    throw new ApiRequestError(statusText, status, text || 'Unknown');
  }
}

export const getLandingRegister = async (data) =>
  get('/landing_register', data);
export const getStripeSubscription = async () => get('/stripe/subscriptions');
export const getPaypalSubscription = async () => get('/paypal/subscriptions');
export const postLandingRegister = async (data) =>
  post('/landing_register', data);
export const postPaypalSubscription = async (data) =>
  post('/paypal/subscription', data);
export const postCancelPaypalSubscription = async (data) =>
  post('/paypal/subscription/cancel', data);
export const postPausePaypalSubscription = async (data) =>
  post('/paypal/subscription/pause', data);
export const postChangePaypalSubscription = async (data) =>
  post('/paypal/subscription/revise', data);

export const createCheckoutSession = async (data) =>
  post('/stripe/checkout', data);
export const postStripeSubscriptionCreate = async (data) =>
  post('/stripe/subscription/create', data);
export const postStripeSubscriptionUpdate = async (data) =>
  post('/stripe/subscription/update', data);
export const postStripeSubscriptionCancel = async (data) =>
  post('/stripe/subscription/cancel', data);
export const postStripeSetupIntent = async (data) =>
  post('/stripe/setup_intent', data);
export const postStripeCreatePaymentIntent = async (data) =>
  post('/stripe/payment_intent', data);
export const postPauseStripeSubscription = async (data) =>
  post('/stripe/subscription/pause', data);
export const postChangeStripeSubscription = async (data) =>
  post('/stripe/subscription/create/new', data);

export const postRegisterCookie = async (data) => post('/cookies', data);

export const postLoginEmail = async (data) => post('/login/email', data);
export const postLoginEmailCode = async (data) =>
  post('/login/email/code', data);

//
// curl 'https://dev-web.photo-filter.com/web/cookies' \
//   -H 'authority: dev-web.photo-filter.com' \
//   -H 'pragma: no-cache' \
//   -H 'cache-control: no-cache' \
//   -H 'access-control-allow-origin: *' \
//   -H 'access-control-allow-credentials: true' \
//   -H 'debug: true' \
//   -H 'user-agent: Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1' \
//   -H 'content-type: application/json;charset=utf-8' \
//   -H 'accept: */*' \
//   -H 'sec-gpc: 1' \
//   -H 'origin: https://dev-web.photo-filter.com' \
//   -H 'sec-fetch-site: same-origin' \
//   -H 'sec-fetch-mode: cors' \
//   -H 'sec-fetch-dest: empty' \
//   -H 'referer: https://dev-web.photo-filter.com/' \
//   -H 'accept-language: en-US,en;q=0.9' \
//   -H 'cookie: amp_9ec95d=eVg5pR9AATVeARtj9olpuO...1fdd9h588.1fdd9h58b.1.0.1; _fbp=fb.1.1629312554774.800699470' \
//   --data-raw '{"cookie":"amp_9ec95d=eVg5pR9AATVeARtj9olpuO...1fdd9h588.1fdd9h58b.1.0.1; _fbp=fb.1.1629312554774.800699470"}' \
//   --compressed
