import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import de from './locales/de.json';
import es from './locales/es.json';
import fr from './locales/fr.json';
import it from './locales/it.json';
import pt from './locales/pt.json';
import ru from './locales/ru.json';
import dayjs from 'dayjs';

const dayjsLocales = {
  en: import('dayjs/locale/en'),
  de: import('dayjs/locale/de'),
  es: import('dayjs/locale/es'),
  fr: import('dayjs/locale/fr'),
  it: import('dayjs/locale/it'),
  pt: import('dayjs/locale/pt'),
  ru: import('dayjs/locale/ru'),
};

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
  es: {
    translation: es,
  },
  fr: {
    translation: fr,
  },
  it: {
    translation: it,
  },
  pt: {
    translation: pt,
  },
  ru: {
    translation: ru,
  },
};

const locale = localStorage.getItem('smmhub_locale') || 'en';

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  lng: locale,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

dayjsLocales[locale].then(() => {
  dayjs.locale(locale);
});

export default i18n;
